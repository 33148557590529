// extracted by mini-css-extract-plugin
export var ArtistDescription = "MathiasBensimon-module--ArtistDescription--uaE0j";
export var ArtistInfos = "MathiasBensimon-module--ArtistInfos--jDMIm";
export var ButtonWrapper = "MathiasBensimon-module--ButtonWrapper --OxdXs";
export var CardWrapper = "MathiasBensimon-module--CardWrapper--7bIMK";
export var CarrouselWrapper2 = "MathiasBensimon-module--CarrouselWrapper2--z+Fa9";
export var Citations = "MathiasBensimon-module--Citations--XwB1A";
export var DescriptionWrapper = "MathiasBensimon-module--DescriptionWrapper--skaJE";
export var ImageWrapper = "MathiasBensimon-module--ImageWrapper--5d1KL";
export var LinkWrapper = "MathiasBensimon-module--LinkWrapper--P70tJ";
export var MobileProtrait = "MathiasBensimon-module--MobileProtrait--rHgpr";
export var More = "MathiasBensimon-module--More--73EVl";
export var MoreButton = "MathiasBensimon-module--MoreButton--txg9e";
export var NameWrapper = "MathiasBensimon-module--NameWrapper--RQ1iy";
export var PdpWrapper = "MathiasBensimon-module--PdpWrapper--1oK9B";
export var PhotosWrapper = "MathiasBensimon-module--PhotosWrapper--cA4e5";
export var ProfilWrapper = "MathiasBensimon-module--ProfilWrapper--1IzA0";
export var TitleWrapper = "MathiasBensimon-module--TitleWrapper--7ke5p";
export var Wrapper = "MathiasBensimon-module--Wrapper--WlZK7";